import "can/model/";


export default can.Model.extend({
    findAll: function( params ) {
        "use strict";

        const isAllUsers = (params.hasOwnProperty("id") && params.id) ? `?user-id=${params.id}` : "";

        return can.ajax({
            url: `/api/v2/userPreferences${isAllUsers}`,
            type: "GET",
            dataType: "json",
            async: true
        });
    },

    create: function( params ) {
        "use strict";
        return can.ajax({
            url: "/api/v2/userPreferences",
            type: "POST",
            data: JSON.stringify( params.userPreferences ),
            contentType: "application/json"
        });
    },

    update: function( id, params ) {
        "use strict";
        return can.ajax({
            url: "/api/v2/userPreferences",
            type: "PUT",
            data: JSON.stringify( params.userPreferences ),
            contentType: "application/json"
        });
    }
}, {});
